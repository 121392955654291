#editButton {
    margin-left: 10px;
}

#deleteButton {
    margin-right: 5px;
}

#OrganisationMediaLibrariesTable > table > tbody > tr:nth-child(1) > td:nth-child(4) {
    text-align:center;
    width: 190px;
}

.OrganisationMediaLibrariesTable table {
    margin-bottom: 0px

}

.OrganisationMediaLibrariesTable .rules {
    margin-top: 10px;
    display: block;
    clear:both;
    text-align: right;

}

.OrganisationMediaLibrariesTable tr td:first-child {
    /* vertical-align: middle; */
    padding-top: 18px;
    font-weight: bold;
}