@import url(https://fonts.googleapis.com/css?family=Open+Sans);
body {
    margin: 0;
    padding: 0;
    /* font-family: "Open Sans", sans-serif; */
    font-size: 14px;
    color: #2f2f2f;
    background-color: #fff;
}

select.form-control,
textarea.form-control,
input.form-control {
    font-size: 16px;
}
input[type=file] {
    width: 100%;
}
#wrapper {
    padding-left: 0;
}

#content-wrapper {
    width: 100%;
    padding: 0;
    background-color: #fff;
}

@media(min-width:769px) {
    #wrapper {
        padding-left: 196px;
    }
}
#content-wrapper {
    padding: 10px !important;
}

.label {

    font-size: 90%;
}

/* Side Navigation */

@media(min-width:768px) {
    .NavigationSidebar .side-nav {
        position: fixed;
        top: 51px;
        left: 196px;
        width: 196px;
        margin-left: -196px;
        border: none;
        border-radius: 0;
        overflow-y: auto;
        background-color: #f5f5f5;
        bottom: 0;
        overflow-x: hidden;
        padding-bottom: 40px;
    }

    .NavigationSidebar .side-nav>li>a {
        width: 196px;
    }

    .NavigationSidebar .side-nav li a:hover,
    .NavigationSidebar .side-nav li a:focus {
        color:#111 !important;
        outline: none;
        /* background-color: #dddddd !important; */
    }
}

@media (max-width: 768px) {
    .NavigationSidebar .navbar-default .navbar-nav .open .dropdown-menu > li > a {
        color: #fff;
    }
    .NavigationSidebar .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
    .NavigationSidebar .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
          color: #c1c1c1;
    }
}

.navSelect {
    margin-left: 15px;
    width:auto;
    display: block;
}

/* Link */
.NavigationSidebar .navbar-default .navbar-nav > li > a {
    color: #fff;
}
.NavigationSidebar .navbar-default .navbar-nav > li > a:hover,
.NavigationSidebar .navbar-default .navbar-nav > li > a:focus {
    color: #c1c1c1;
}
.NavigationSidebar .navbar-default .navbar-nav > .active > a,
.NavigationSidebar .navbar-default .navbar-nav > .active > a:hover,
.NavigationSidebar .navbar-default .navbar-nav > .active > a:focus {
    color: #111;
    /* background-color: #dddddd; */
}
.NavigationSidebar .navbar-default .navbar-nav > .open > a,
.NavigationSidebar .navbar-default .navbar-nav > .open > a:hover,
.NavigationSidebar .navbar-default .navbar-nav > .open > a:focus {
    color: #fff;
    background-color: #dddddd;
}
/* Caret */
.NavigationSidebar .navbar-default .navbar-nav > .dropdown > a .caret {
    border-top-color: #fff;
    border-bottom-color: #fff;
}
.NavigationSidebar .navbar-default .navbar-nav > .dropdown > a:hover .caret,
.NavigationSidebar .navbar-default .navbar-nav > .dropdown > a:focus .caret {
    border-top-color: #c1c1c1;
    border-bottom-color: #c1c1c1;
}
.NavigationSidebar .navbar-default .navbar-nav > .open > a .caret,
.NavigationSidebar .navbar-default .navbar-nav > .open > a:hover .caret,
.NavigationSidebar .navbar-default .navbar-nav > .open > a:focus .caret {
    border-top-color: #FFF;
    border-bottom-color: #FFF;
}

.NavigationSidebar .side-nav {
    /* margin-top:2px; */
    border-right: 1px solid #dddddd;
}
.NavigationSidebar .side-nav>li>ul {
    padding: 0;
}

.NavigationSidebar .side-nav a {
    font-size: 16px !important;
    color:#666666 !important;
}

.NavigationSidebar .side-nav .active a{
    font-size: 16px !important;
    color:#111 !important;
}

.NavigationSidebar .side-nav>li>ul>l>a {
    display: block;
    padding: 10px 15px 10px 38px;
    text-decoration: none;
    color: #666666 !important;
}

.NavigationSidebar .side-nav>li>ul>li>a:hover {
    color: #111;
}

@media (max-width: 768px) {

    .navbar-nav {
        float: none!important;
		margin-top: 7.5px;
	}
	.navbar-nav>li {
        float: none;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

ul.side-nav {
    display: flex;
    flex-direction: column;
}

ul.side-nav .spacer {
    flex-grow: 1;
}

.terms {
    margin-left: auto;
    margin-right: auto;
    display:block;
}


.Navigation .divide-nav{
  margin-top: 36px;
  height: 50px;
  width: 100%;
  background-color: #428bca;
}

.Navigation .divide-text{
    color:#fff;
    line-height: 20px;
    font-size:20px;
    padding: 15px 0;
}

.Navigation .fa {
    -webkit-backface-visibility: hidden !important;
            backface-visibility: hidden !important;
}

/* navbar */
.Navigation .navbar-default {
    background-color: #333333;
    border-color: #333;
}
/* Title */
.Navigation .navbar-default .navbar-brand {
    color: #fff;
}
.Navigation .navbar-default .navbar-brand:hover,
.Navigation .navbar-default .navbar-brand:focus {
    color: #5E5E5E;
}

.Navigation .navbar-default .navbar-brand {
    padding: 10px 15px !important;
}


/* Link */
.Navigation .navbar-default .navbar-nav > li > a {
    color: #fff;
}
.Navigation .navbar-default .navbar-nav > li > a:hover,
.Navigation .navbar-default .navbar-nav > li > a:focus {
    color: #c1c1c1;
}
.Navigation .navbar-default .navbar-nav > .active > a,
.Navigation .navbar-default .navbar-nav > .active > a:hover,
.Navigation .navbar-default .navbar-nav > .active > a:focus {
    color: #555;
    background-color: #f5f5f5;
}
.Navigation .navbar-default .navbar-nav > .open > a,
.Navigation .navbar-default .navbar-nav > .open > a:hover,
.Navigation .navbar-default .navbar-nav > .open > a:focus {
    color: #fff;
    background-color: #333;
}
/* Caret */
.Navigation .navbar-default .navbar-nav > .dropdown > a .caret {
    border-top-color: #fff;
    border-bottom-color: #fff;
}
.Navigation .navbar-default .navbar-nav > .dropdown > a:hover .caret,
.Navigation .navbar-default .navbar-nav > .dropdown > a:focus .caret {
    border-top-color: #c1c1c1;
    border-bottom-color: #c1c1c1;
}
.Navigation .navbar-default .navbar-nav > .open > a .caret,
.Navigation .navbar-default .navbar-nav > .open > a:hover .caret,
.Navigation .navbar-default .navbar-nav > .open > a:focus .caret {
    border-top-color: #FFF;
    border-bottom-color: #FFF;
}
/* Mobile version */
.Navigation .navbar-default .navbar-toggle {
    border: none;
 outline: none;

}
.Navigation .navbar-default .navbar-toggle:hover,
.Navigation .navbar-default .navbar-toggle:focus {
    background-color: #333;
}
.Navigation .navbar-default .navbar-toggle .icon-bar {
    background-color: #FFF;
}
.menu_dropdown {
    padding-top: 10px;
}

@media (max-width: 768px) {
    .Navigation .navbar-default .navbar-nav .open .dropdown-menu > li > a {
        color: #fff;
    }
    .Navigation .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
    .Navigation .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
          color: #c1c1c1;
    }
}

@media (max-width: 768px) {
    .navbar-header {
        float: none;
    }
    .navbar-left,.navbar-right {
        float: none !important;
    }
    .navbar-toggle {
        display: block;
    }
    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
    }
    .navbar-fixed-top {
		top: 0;
		border-width: 0 0 1px;
	}
    .navbar-collapse.collapse {
        display: none!important;
    }
    .navbar-nav {
        float: none!important;
		margin-top: 7.5px;
	}
	.navbar-nav>li {
        float: none;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .collapse.in{
  		display:block !important;
	}
}

.DismissableAlert h4 {
    /* font-family: "Open Sans", sans-serif; */
    font-weight: 600;
}

@media all {

    .Engineers .page-header {
        margin-top: 15px;
    }
    
    .Engineers {
        padding: 0px 0;
        background-color: #f5f5f5;
        position: absolute;
        top: 0px;
        bottom: 0px;
        width: 100%;
    }

    .Engineers form {
        margin: 15px auto;
    }

    .Engineers img {
        margin: 25px auto;
    }

    .Engineers .panel {
        padding: 10px;
        margin-top: 40px;
    }

    .CenteredLinks small {
        text-align: center;
    }

    .Engineers h1  {
        text-align: center;
    }
    .Engineers .mainHeading {
        color: #DA3C7C;
    }

    .Engineers .breadcrumb {
        /* padding: -5px; */
        margin-left: 5px;
        margin-right: 5px;
    }

    .Engineers .PaginationPanel .panel-default {
        padding: 0px;
        margin: 5px;
        margin-top: -5px;
    }

    .Engineers .logout {
        margin-left: 5px;
        text-align: center;
    }

    .Engineers .PaginationPanel .panel-table .panel-body .table-bordered > tbody > tr > td:last-of-type {
        padding-left: 10px;
    }
}

.SpinnerButton .spinning.glyphicon {
    margin-right: 7px;
    top: 2px;
    -webkit-animation: spin 1s infinite linear;
            animation: spin 1s infinite linear;
}
@-webkit-keyframes spin {
    from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
    to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}
@keyframes spin {
    from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
    to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}


.PaginationPanel .panel-table .panel-body{
    padding:0;
}

.PaginationPanel table {
    margin-bottom: 0px;
}

.PaginationPanel .panel-table .panel-body .table-bordered{
    border-style: none;
    margin:0;
}

.PaginationPanel .panel-table .panel-body .table-bordered > thead > tr > th:last-of-type {
    text-align:center;
    width: 100px;
}

.PaginationPanel .panel-table .panel-body .table-bordered > thead > tr > th:last-of-type,
.PaginationPanel .panel-table .panel-body .table-bordered > tbody > tr > td:last-of-type {
    border-right: 0px;
}

.PaginationPanel .panel-table .panel-body .table-bordered > thead > tr > th:first-of-type,
.PaginationPanel .panel-table .panel-body .table-bordered > tbody > tr > td:first-of-type {
    border-left: 0px;
}

.PaginationPanel .panel-table .panel-body .table-bordered > tbody > tr > td {
    padding-left: 15px;
}

.PaginationPanel .panel-table .panel-body .table-bordered > thead > tr > th {
    padding-left: 15px;
}

.PaginationPanel .panel-table .panel-body .table-bordered > tbody > tr > td:last-of-type {
    padding-left: 0px;
}

.PaginationPanel .panel-table .panel-body .table-bordered > thead > tr > th:last-of-type {
    padding-left: 0px;
}

.PaginationPanel .panel-table .panel-body .table-bordered > tbody > tr:first-of-type > td{
    border-bottom: 0px;
}

.PaginationPanel .panel-table .panel-body .table-bordered > thead > tr:first-of-type > th{
    border-top: 0px;
}

.PaginationPanel .panel-table .panel-body .table-bordered > tbody > tr:last-of-type > td{
    border-bottom: 0px;
}

.PaginationPanel .panel-table .panel-body .table-bordered > tbody > tr > td{
    line-height: 34px;
}

.PaginationPanel .panel-table .panel-footer .pagination{
    margin:0;
}

.PaginationPanel .panel-table .panel-footer .col{
    line-height: 34px;
    height: 34px;
}



#editButton {
    margin-left: 10px;
}

#deleteButton {
    margin-right: 5px;
}

.Organisations {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.Organisations .page-header {
    margin-top: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Organisations .header-pagination {

    margin-right: 75px !important;
    margin-top: 10px !important;

}

.Organisations h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.CreateOrganisationForm .nopadding {
   padding: 0 !important;
   margin: 0 !important;
}

@media all {
     .PlainPanel {
         /* padding: 60px 0; */
    }

    .PlainPanel {
        /* margin-top: 20px; */
    }

     .PlainPanel .panel-table .panel-footer .col{
         height: 34px;
         line-height: 34px;
    }

     .h3 {
         /* font-family: "Open Sans", sans-serif; */
         font-size: 15px;
         color: #333;
         height: 30px;
         line-height: 30px;
    }
     .PlainPanel .panel-table .panel-body .table-bordered > tbody > tr > td {
         line-height: 34px;
    }
     .PlainPanel .panel-table .panel-body{
         padding: 15px 0px 0px 0px
    }
     .PlainPanel .panel-table .panel-body .table-bordered{
         border-style: none;
         margin:0;
    }
     .PlainPanel .panel-table .panel-body .table-bordered > thead > tr > th {
         padding-left: 15px;
    }
     .PlainPanel .panel-table .panel-body .table-bordered > thead > tr > th:last-of-type {
         text-align:center;
         width: 100px;
    }
     .PlainPanel .panel-table .panel-body .table-bordered > tbody > tr > td {
         padding-left: 15px;
    }
     .PlainPanel .panel-table .panel-body .table-bordered > tbody > tr > td:last-of-type {
         padding-left: 0px;
    }
     .PlainPanel .panel-table .panel-body .table-bordered > tbody > tr > td > a {
         text-align:center;
         margin:2px;
    }
     .PlainPanel .panel-table .panel-body .table-bordered > tbody > tr:first-of-type > td{
         border-bottom: 0px;
    }
     .PlainPanel .panel-table .panel-body .table-bordered > thead > tr:first-of-type > th{
         /* border-top: 0px; */
    }
     .PlainPanel .panel-table .panel-footer .pagination {
         margin:0;
    }

    .PlainPanel .panel-heading > div > div> form > input {
        width:100%;
    }

    .PlainPanel .panel-heading > div > div> form > input {
        width:100%;
    }
}

.CreateOrganisation {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.CreateOrganisation .page-header {
    margin-top: 15px;
}

.CreateOrganisation h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.EditOrganisationForm .nopadding {
   padding: 0 !important;
   margin: 0 !important;
}

.EditOrganisation {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.EditOrganisation .page-header {
    margin-top: 15px;
}

.EditOrganisation h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.PlayerPickerModal {
    width: 70%;
}

.AttributePlayers {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.AttributePlayers .page-header {
    margin-top: 15px;
}

.AttributePlayers h1 > .btn {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.GlobalPagePlayers {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.GlobalPagePlayers .page-header {
    margin-top: 15px;
}

.GlobalPagePlayers h1 > .btn {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.UserPlayers {
	margin-top: 35px;
	padding: 0px 10px 0px 10px;
}

.UserPlayers .page-header {
	margin-top: 15px;
}

.UserPlayers h1 > .btn {
	float: right;
	position: relative;
	right: 0px;
	bottom: 5px;
	top: 3px;
}

.td-center {
	padding-left: 5px !important;
	text-align: center;
}

.td-center a {
	margin: 0;
}

.Players {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.Players .page-header {
    margin-top: 15px;
}

.Players h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.CreatePlayerForm .nopadding {
   padding: 0 !important;
   margin: 0 !important;
}

.CreatePlayer {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.CreatePlayer .page-header {
    margin-top: 15px;
}

.CreatePlayer h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.EditPlayerForm .nopadding {
   padding: 0 !important;
   margin: 0 !important;
}

.EditPlayer {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.EditPlayer .page-header {
    margin-top: 15px;
}

.EditPlayer h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.AttributePickerModal {
    width: 70%;
}

.PlayerAttributes {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.PlayerAttributes .page-header {
    margin-top: 15px;
}

.PlayerAttributes .header-button {

    float: right;
    position: relative;
    margin-right: 5px !important;
}
.PlayerAttributes h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}


.PlayerPages {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.PlayerPages .page-header {
    margin-top: 15px;
}

.PlayerPages h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.PlayerPages .header-button {

    margin-right: 5px !important;
}
.EditPageForm .nopadding {
   padding: 0 !important;
   margin: 0 !important;
}

.EditPage {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.EditPage .page-header {
    margin-top: 15px;
}

.EditPage h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.EditPage .header-button {

    margin-right: 5px !important;
}
.EditPageSnippetForm .nopadding {
   padding: 0 !important;
   margin: 0 !important;
}

.EditPageSnippet {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.EditPageSnippet .page-header {
    margin-top: 15px;
}

.EditPageSnippet h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.EditPageSnippet .header-button {
    margin-right: 5px !important;
}
.EditGlobalSnippetForm .nopadding {
   padding: 0 !important;
   margin: 0 !important;
}

.EditGlobalSnippet {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.EditGlobalSnippet .page-header {
    margin-top: 15px;
}

.EditGlobalSnippet h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.PageSnippetsTable thead > tr > th:last-of-type {
    text-align:center;
    width: 100px;
}

.HeaderFooterPanel .panel-table .panel-body{
    padding:0;
}

.HeaderFooterPanel table {
    margin-bottom: 0px

}

.HeaderFooterPanel .panel-table .panel-body .table-bordered{
    border-style: none;
    margin:0;
}

.HeaderFooterPanel .panel-table .panel-body .table-bordered > thead > tr > th:last-of-type {
    text-align:center;
    width: 100px;
}

.HeaderFooterPanel .panel-table .panel-body .table-bordered > thead > tr > th:last-of-type,
.HeaderFooterPanel .panel-table .panel-body .table-bordered > tbody > tr > td:last-of-type {
    border-right: 0px;
}

.HeaderFooterPanel .panel-table .panel-body .table-bordered > thead > tr > th:first-of-type,
.HeaderFooterPanel .panel-table .panel-body .table-bordered > tbody > tr > td:first-of-type {
    border-left: 0px;
}

.HeaderFooterPanel .panel-table .panel-body .table-bordered > tbody > tr > td {
    padding-left: 15px;
}

.HeaderFooterPanel .panel-table .panel-body .table-bordered > thead > tr > th {
    padding-left: 15px;
}

.HeaderFooterPanel .panel-table .panel-body .table-bordered > tbody > tr > td:last-of-type {
    padding-left: 0px;
}

.HeaderFooterPanel .panel-table .panel-body .table-bordered > thead > tr > th:last-of-type {
    padding-left: 0px;
}

.HeaderFooterPanel .panel-table .panel-body .table-bordered > tbody > tr:first-of-type > td{
    border-bottom: 0px;
}

.HeaderFooterPanel .panel-table .panel-body .table-bordered > thead > tr:first-of-type > th{
    border-top: 0px;
}

.HeaderFooterPanel .panel-table .panel-body .table-bordered > tbody > tr:last-of-type > td{
    border-bottom: 0px;
}


.HeaderFooterPanel .panel-table .panel-footer .pagination{
    margin:0;
}

.HeaderFooterPanel .panel-table .panel-footer .col{
    line-height: 34px;
    height: 34px;
}

.HeaderFooterPanel .panel-table .panel-body .table-bordered > tbody > tr > td{
    line-height: 34px;
}

.Page {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.Page .page-header {
    margin-top: 15px;
}

.Page h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.Page #previewButton {
    margin-right: 10px;
}

.Page h1 > .dropdown {
    float: right !important;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.Page .header-button {
    margin-right: 5px !important;
}
.CreatePageSnippetForm .nopadding {
   padding: 0 !important;
   margin: 0 !important;
}

.CreatePageSnippet {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.CreatePageSnippet .page-header {
    margin-top: 15px;
}

.CreatePageSnippet h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.AddPageSnippetForm .nopadding {
   padding: 0 !important;
   margin: 0 !important;
}

.AddPageSnippet {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.AddPageSnippet .page-header {
    margin-top: 15px;
}

.AddPageSnippet h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.MediaSnippetMediaLibrariesTable thead > tr > th:last-of-type {
    text-align:center;
    width: 115px;
}

.MediaLibraryPickerModal {
    width: 70%;
}

.MediaSnippet {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.MediaSnippet .page-header {
    margin-top: 15px;
        min-height:50px;
}

.MediaSnippet h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.MediaSnippet h1 > .btn {
    float: right !important;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.MediaSnippet h1 > .dropdown {
    float: right !important;
    position: relative;
    right: 0px;
    margin-left: 10px;
    bottom: 5px;
    top:3px;
}

.MediaSnippet #previewButton {
    margin-right: 0;
}

.MediaSnippet .header-button {
    margin-right: 5px !important;
}
.MediaPickerModal {
    width: 70%;
}

.MediaPickerModal {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.MediaPickerModal .page-header {
    margin-top: 15px;
}

.MediaPickerModal h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.MediaPickerModal .upload-drop-zone {
  height: 200px;
  margin-top: 20px;
  border-width: 2px;
  margin-bottom: 20px;
}

/* skin.css Style*/
.MediaPickerModal .upload-drop-zone {
  color: #ccc;
  border-style: dashed;
  border-color: #ccc;
  line-height: 200px;
  text-align: center
}

#editButton {
    margin-left: 10px;
}

#deleteButton {
    margin-right: 5px;
}

.MediaTable thead > tr > th:last-of-type {
    text-align:center;
    width: 150px;
}

.MediaTable table {
    margin-bottom: 0px

}

.MediaTable .rules {
    margin-top: 10px;
    display: block;
    clear:both;
    text-align: right;

}

.MediaTable tr td:first-child {
    /* vertical-align: middle; */
    padding-top: 18px;
    font-weight: bold;
}

.button-container {
    position: absolute;
    bottom: 28px; /* Adjust padding from the bottom */
    left: 0; /* Align to the left side of the cell */
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
td {
    position: relative; /* Ensures the button is positioned relative to the table cell */
}
.MediaSnippetMediaLibrary {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.MediaSnippetMediaLibrary .page-header {
    margin-top: 15px;
    min-height:50px;
}

.MediaSnippetMediaLibrary h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.MediaSnippetMediaLibrary #previewButton {
    margin-right: 10px;
}

.TextLibrarySnippetTextLibrariesTable thead > tr > th:last-of-type {
    text-align:center;
    width: 115px;
}

.TextLibraryPickerModal {
    width: 70%;
}

.TextLibrarySnippet {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.TextLibrarySnippet .page-header {
    margin-top: 15px;
        min-height:50px;
}

.TextLibrarySnippet h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.TextLibrarySnippet h1 > .btn {
    float: right !important;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.TextLibrarySnippet h1 > .dropdown {
    float: right !important;
    position: relative;
    right: 0px;
    margin-left: 10px;
    bottom: 5px;
    top:3px;
}

.TextLibrarySnippet #previewButton {
    margin-right: 0;
}

.TextLibrarySnippet .header-button {

    margin-right: 5px !important;
}

#editButton {
    margin-left: 10px;
}

#deleteButton {
    margin-right: 5px;
}

.TextLibraryTextTable thead > tr > th:last-of-type {
    text-align:center;
    width: 150px;
}

.TextLibraryTextTable table {
    margin-bottom: 0px
}
.TextLibrarySnippetTextLibrary {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.TextLibrarySnippetTextLibrary .page-header {
    margin-top: 15px;
    min-height:50px;
}

.TextLibrarySnippetTextLibrary h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.TextLibrarySnippetTextLibrary #previewButton {
    margin-right: 10px;
}

.TextLibrarySnippetTextLibrary .header-button {

    margin-right: 5px !important;
}
.CreateGlobalSnippetForm .nopadding {
   padding: 0 !important;
   margin: 0 !important;
}

.CreateGlobalSnippet {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.CreateGlobalSnippet .page-header {
    margin-top: 15px;
}

.CreateGlobalSnippet h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.editor-wrapper {
	margin: 0;
	font-family: Arial, sans-serif;
}

.editor-wrapper * {
	box-sizing: border-box;
}

/* Canvas Styles */
#template-snippet-canvas-wrapper {
	background: #ccc;
	padding: 5rem;
	overflow: scroll;
	height: 80vh;
	position: relative;
}

#template-snippet-canvas {
	box-sizing: border-box;
	background: #ffffff;
}

/* Menu Styles */
#template-snippet-menu {
	padding: 1rem;
	background: #f1f1f1;
	border-radius: 1rem;
	height: 80vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}

textarea:focus {
	border: solid 3px yellow;
}

.ts-menu-group {
	text-align: center;
	font-size: 1.75rem;
	width: 100%;
}

.ts-menu-col {
	padding: 0.25rem;
}

.ts-menu-row button,
.ts-menu-row input {
	margin: 0;
}

.ts-menu-row {
	margin: 0;
	padding: 0;
	width: 100%;
}

.editor-wrapper .p-0 {
	padding: 0;
}

.editor-wrapper .btn {
	padding: 0.8rem 1.2rem;
}

.editor-wrapper .btn-dark {
	background: #343a40;
	color: #fff;
}

.editor-wrapper .btn-dark.active {
	background: #1e2124;
}

.editor-wrapper .btn-dark:hover {
	background: #23272b;
	color: #fff;
}

.editor-wrapper .btn-fw {
	font-size: 1.5rem;
	font-weight: 700;
}

.ts-menu-row form {
	height: 100%;
}

.ts-menu-row form input[type='number'] {
	font-size: 1.2rem;
	height: 38px;
}

.ts-label {
	padding: 1.5rem;
	border-radius: 0.75rem;
	cursor: pointer;
	border: 1px solid transparent;
}

.ts-label.last {
	background: #f4f4f4;
	border: 1px solid #bbb;
}

.ts-label.last:hover {
	background: #ececec;
}

#modal-form-wrapper {
	margin: 0;
	background: #ddd;
	border-radius: 10px;
	padding: 2rem 1rem;
}

#modal-bg-picker {
	margin: 1rem 0;
	padding: 2rem 1rem;
	border-radius: 10px;
	background: #ddd;
	overflow: auto;
	max-height: 500px;
}

.modal-bg-thumbnail-group {
	display: flex;
	flex-wrap: wrap;
}

.modal-bg-thumbnail {
	display: table;
	margin: 1rem;
	padding: 0.5rem;
	background: white;
	box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
	max-height: 120px;
	cursor: pointer;
}

.modal-bg-thumbnail:hover {
	box-shadow: 0 3px 10px rgba(0, 0, 0, 0.4);
}

.modal-bg-thumbnail.selected {
	background: #5cb85c;
}

.modal-80 {
	width: 60vw;
}

.color-picker-tile-wrapper {
	padding: 0;
}

.color-picker-tile {
	padding: 1.5rem;
	border-radius: 5px;
	border: 2px solid #f1f1f1;
}

.color-picker-tile:hover {
	border: solid 2px #343a40;
}

.EditTemplateSnippet {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.EditTemplateSnippet .page-header {
    margin-top: 15px;
}

.EditTemplateSnippet h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.EditTemplateSnippet .header-button {
    margin-right: 5px !important;
}
.EditMediaText {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.EditMediaText .page-header {
    margin-top: 15px;
}

.EditMediaText h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.EditMediaText .header-button {

    margin-right: 5px !important;
}

.EditTextLibraryText {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.EditTextLibraryText .page-header {
    margin-top: 15px;
}

.EditTextLibraryText h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.TextLibraryTextRules {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.TextLibraryTextRules .page-header {
    margin-top: 15px;
    min-height:50px;
}

.TextLibraryTextRules h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.TextLibraryTextRules #previewButton {
    margin-right: 10px;
}

.TextLibraryTextRules h1 > .btn {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.TextLibraryTextRules .buttonContainer {
    float: inline-end;
    margin-right: -5px;
    vertical-align: middle;
}
.TextLibraryTextRules .logicSeperator {
    margin-bottom: 20px;
}

.EditTextLibraryTextRule {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.EditTextLibraryTextRule .page-header {
    margin-top: 15px;
}

.EditTextLibraryTextRule h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.CreateTextLibraryTextForm .nopadding {
    padding: 0 !important;
    margin: 0 !important;
 }
 

.CreateTextLibraryTextRuleForm .nopadding {
    padding: 0 !important;
    margin: 0 !important;
 }
 
.CreateTextLibraryTextRule {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.CreateTextLibraryTextRule .page-header {
    margin-top: 15px;
}

.CreateTextLibraryTextRule h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.CreateGlobalPageForm .nopadding {
   padding: 0 !important;
   margin: 0 !important;
}

.CreateGlobalPage {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.CreateGlobalPage .page-header {
    margin-top: 15px;
}

.CreateGlobalPage h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.MediaSnippetMediaLibrariesTable thead > tr > th:last-of-type {
    text-align:center;
    width: 115px;
}

.FactTypePickerModal {
    width: 70%;
}

.FactSnippet {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.FactSnippet .page-header {
    margin-top: 15px;
}

.FactSnippet h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.FactSnippet h1 > .btn {
    float: right !important;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}
.FactSnippet #previewButton {
    margin-right: 0;
}

.FactSnippet .header-button {

    margin-right: 5px !important;
}

.QuestionSnippetQuestionTypesTable thead > tr > th:last-of-type {
    text-align:center;
    width: 115px;
}

.QuestionTypePickerModal {
    width: 70%;
}

.QuestionSnippet {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.QuestionSnippet .page-header {
    margin-top: 15px;
    min-height:50px;
}

.QuestionSnippet h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.QuestionSnippet h1 > .btn {
    float: right !important;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}
.QuestionSnippet #previewButton {
    margin-right: 0;
}

.QuestionSnippet .header-button {
    margin-right: 5px !important;
}

.TextSnippetTextsTable thead > tr > th:last-of-type {
    text-align:center;
    width: 115px;
}

.TextSnippetTextsTable table {
    margin-bottom: 0px
}

.TextSnippet {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.TextSnippet .page-header {
    margin-top: 15px;
    min-height:50px;
}

.TextSnippet h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.TextSnippet h1 > .btn {
    float: right !important;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}
.TextSnippet #previewButton {
    margin-right: 0;
}

.TextSnippet .header-button {

    margin-right: 5px !important;
}

.TextSnippet .header-button {

    margin-right: 5px !important;
}
.CreatePlayerForm .nopadding {
   padding: 0 !important;
   margin: 0 !important;
}

.CreateText {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.CreateText .page-header {
    margin-top: 15px;
}

.CreateText h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.EditAttributeForm .nopadding {
   padding: 0 !important;
   margin: 0 !important;
}

.EditText {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.EditText .page-header {
    margin-top: 15px;
}

.EditText h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.TextRules {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.TextRules .page-header {
    margin-top: 15px;
    min-height:50px;
}

.TextRules h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.TextRules #previewButton {
    margin-right: 10px;
}

.TextRules h1 > .btn {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.TextRules .buttonContainer {
    float: inline-end;
    margin-right: -5px;
    vertical-align: middle;
}
.TextRules .logicSeperator {
    margin-bottom: 20px;
}

.TextRules .header-button {

    margin-right: 5px !important;
}
.CreateMediaRuleForm .nopadding {
   padding: 0 !important;
   margin: 0 !important;
}

.CreateTextRule {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.CreateTextRule .page-header {
    margin-top: 15px;
}

.CreateTextRule h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.EditTextRuleForm .nopadding {
   padding: 0 !important;
   margin: 0 !important;
}

.EditTextRule {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.EditTextRule .page-header {
    margin-top: 15px;
}

.EditTextRule h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.SnippetRules {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.SnippetRules .page-header {
    margin-top: 15px;
    min-height:50px;
}

.SnippetRules h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.SnippetRules #previewButton {
    margin-right: 10px;
}

.SnippetRules h1 > .btn {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.SnippetRules .buttonContainer {
    float: inline-end;
    margin-right: -5px;
    vertical-align: middle;
}
.SnippetRules .logicSeperator {
    margin-bottom: 20px;
}

.SnippetRules .header-button {
    margin-right: 5px !important;
}

.CreateSnippetRuleForm .nopadding {
   padding: 0 !important;
   margin: 0 !important;
}

.CreateSnippetRule {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.CreateSnippetRule .page-header {
    margin-top: 15px;
}

.CreateSnippetRule h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.EditSnippetRuleForm .nopadding {
   padding: 0 !important;
   margin: 0 !important;
}

.EditSnippetRule {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.EditSnippetRule .page-header {
    margin-top: 15px;
}

.EditSnippetRule h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.CreateMedia {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.CreateMedia .page-header {
    margin-top: 15px;
}

.CreateMedia h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.CreateMedia .upload-drop-zone {
  height: 200px;
  margin-top: 20px;
  border-width: 2px;
  margin-bottom: 20px;
}

/* skin.css Style*/
.CreateMedia .upload-drop-zone {
  color: #ccc;
  border-style: dashed;
  border-color: #ccc;
  line-height: 200px;
  text-align: center
}

.MediaRules {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.MediaRules .page-header {
    margin-top: 15px;
    min-height:50px;
}

.MediaRules h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.MediaRules h1 > .btn {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.MediaRules #previewButton {
    margin-right: 10px;
}

.MediaRules .buttonContainer {
    float: inline-end;
    margin-right: -5px;
    vertical-align: middle;
}
.MediaRules .logicSeperator {
    margin-bottom: 20px;
}

.MediaRules .header-button {

    margin-right: 5px !important;
}
.CreateMediaRuleForm .nopadding {
   padding: 0 !important;
   margin: 0 !important;
}

.CreateMediaRule {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.CreateMediaRule .page-header {
    margin-top: 15px;
}

.CreateMediaRule h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.EditMediaRuleForm .nopadding {
   padding: 0 !important;
   margin: 0 !important;
}

.EditMediaRule {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.EditMediaRule .page-header {
    margin-top: 15px;
}

.EditMediaRule h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

#editButton {
    margin-left: 10px;
}

#deleteButton {
    margin-right: 5px;
}

.TooltipLabel {
    margin-right: 5px;
}

.Users {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.Users .page-header {
    margin-top: 15px;
}

.Users h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.FactTypes {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.FactTypes .page-header {
    margin-top: 15px;
}

.FactTypes h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.CreateFactTypeForm .nopadding {
   padding: 0 !important;
   margin: 0 !important;
}

.CreateFactType {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.CreateFactType .page-header {
    margin-top: 15px;
}

.CreateFactType h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.EditFactForm .nopadding {
   padding: 0 !important;
   margin: 0 !important;
}

.EditFactType {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.EditFactType .page-header {
    margin-top: 15px;
}

.EditFactType h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.Facts {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.Facts .page-header {
    margin-top: 15px;
}

.Facts h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.CreateFactForm .nopadding {
   padding: 0 !important;
   margin: 0 !important;
}

.CreateFact {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.CreateFact .page-header {
    margin-top: 15px;
}

.CreateFact h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.EditFactForm .nopadding {
   padding: 0 !important;
   margin: 0 !important;
}

.EditFact {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.EditFact .page-header {
    margin-top: 15px;
}

.EditFact h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.QAndA {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.QAndA .page-header {
    margin-top: 15px;
}

.QAndA h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.CreateQuestionForm .nopadding {
   padding: 0 !important;
   margin: 0 !important;
}

.CreateQuestion {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.CreateQuestion .page-header {
    margin-top: 15px;
}

.CreateQuestion h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.EditQuestionForm .nopadding {
   padding: 0 !important;
   margin: 0 !important;
}

.EditQuestion {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.EditQuestion .page-header {
    margin-top: 15px;
}

.EditQuestion h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.QuestionTypes {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.QuestionTypes .page-header {
    margin-top: 15px;
}

.QuestionTypes h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.CreateQuestionTypeForm .nopadding {
   padding: 0 !important;
   margin: 0 !important;
}

.CreateQuestionType {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.CreateQuestionType .page-header {
    margin-top: 15px;
}

.CreateQuestionType h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.EditQuestionForm .nopadding {
   padding: 0 !important;
   margin: 0 !important;
}

.EditQuestionType {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.EditQuestionType .page-header {
    margin-top: 15px;
}

.EditQuestionType h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.OTD {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.OTD .page-header {
    margin-top: 15px;
}

.OTD h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.CreateOTDForm .nopadding {
   padding: 0 !important;
   margin: 0 !important;
}

.CreateOTD {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.CreateOTD .page-header {
    margin-top: 15px;
}

.CreateOTD h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.EditOTDFactForm .nopadding {
   padding: 0 !important;
   margin: 0 !important;
}

.EditOTDFact {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.EditOTDFact .page-header {
    margin-top: 15px;
}

.EditOTDFact h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.TemplateMediaLibraries {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.TemplateMediaLibraries .page-header {
    margin-top: 15px;
}

.TemplateMediaLibraries h1 > .btn {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

#editButton {
    margin-left: 10px;
}

#deleteButton {
    margin-right: 5px;
}

.TemplateMediaTable thead > tr > th:last-of-type {
    text-align:center;
    width: 150px;
}

.TemplateMediaTable table {
    margin-bottom: 0px

}

.TemplateMediaLibrary {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.TemplateMediaLibrary .page-header {
    margin-top: 15px;
    min-height:50px;
}

.TemplateMediaLibrary h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.TemplateMediaLibrary #previewButton {
    margin-right: 10px;
}

.Organisation {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.Organisation .page-header {
    margin-top: 15px;
}

.Organisation h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

/* .footer-tooltip-button {
    padding: 2px 2px 2px 2px !important;
    padding-bottom: 2px !important;
    margin-top: 2px !important;
    margin-bottom: 0 !important;
    line-height: 1 !important;
} */


/* Custom Colored Panels */

.DashboardTile .huge {
    font-size: 40px;
}

.DashboardTile .panel-green {
    border-color: #5cb85c;
}

.DashboardTile .panel-green > .panel-heading {
    border-color: #5cb85c;
    color: #fff;
    background-color: #5cb85c;
}

.DashboardTile .panel-green > a {
    color: #5cb85c;
}

.DashboardTile .panel-green > a:hover {
    color: #3d8b3d;
}

.DashboardTile .panel-red {
    border-color: #d9534f;
}

.DashboardTile .panel-red > .panel-heading {
    border-color: #d9534f;
    color: #fff;
    background-color: #d9534f;
}

.DashboardTile .panel-red > a {
    color: #d9534f;
}

.DashboardTile .panel-red > a:hover {
    color: #b52b27;
}

.DashboardTile .panel-yellow {
    border-color: #f0ad4e;
}

.DashboardTile .panel-yellow > .panel-heading {
    border-color: #f0ad4e;
    color: #fff;
    background-color: #f0ad4e;
}

.DashboardTile .panel-yellow > a {
    color: #f0ad4e;
}

.DashboardTile .panel-yellow > a:hover {
    color: #df8a13;
}

.DashboardTile .panel-turquoise {
    border-color: #00acb8;
}

.DashboardTile .panel-turquoise > .panel-heading {
    border-color: #00acb8;
    color: #fff;
    background-color: #00acb8;
}

.DashboardTile .panel-turquoise > a {
    color: #00acb8;
}

.DashboardTile .panel-turquoise > a:hover {
    color: #00acb8;
}

.DashboardTile .panel-bergundy {
    border-color: #f0566d;
}

.DashboardTile .panel-bergundy > .panel-heading {
    border-color: #f0566d;
    color: #fff;
    background-color: #f0566d;
}

.DashboardTile .panel-bergundy > a {
    color: #f0566d;
}

.DashboardTile .panel-bergundy > a:hover {
    color: #f0566d;
}

.DashboardTile .panel-purple {
    border-color: #ab62d0;
}

.DashboardTile .panel-purple > .panel-heading {
    border-color: #ab62d0;
    color: #fff;
    background-color: #ab62d0;
}

.DashboardTile .panel-purple > a {
    color: #ab62d0;
}

.DashboardTile .panel-purple > a:hover {
    color: #963bc4;
}

.panel-footer {
    position: relative; /* Establish a positioning context */
}

.footer-button-container {
    position: absolute;
    top: 50%;
    right: 3px; /* Adjust as needed */
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    /* Optional: reduce padding/margin if needed */
    margin: 0;
    padding: 2px;
}

 .footer-right {
    display: inline-flex;
    align-items: center;
}

.DashboardTile .footer-right > *:not(:last-child) {
    margin-right: 12px;
}

.DashboardTile .footer-right > *:last-child {
    margin-right: 10px;
}

.DashboardTile.disabled {
    cursor: not-allowed;
    color: gray;
    text-decoration: none;
}
#editButton {
	margin-left: 10px;
}

#deleteButton {
	margin-right: 5px;
}

#players-btn-cell {
	margin: 0;
	padding: 5px;
}

.OrganisationUsers {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.OrganisationUsers .page-header {
    margin-top: 15px;
}

.OrganisationUsers h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.OrganisationUsers .header-button {

    margin-right: 5px !important;
}
.OrganisationPlayers {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.OrganisationPlayers .page-header {
    margin-top: -5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.OrganisationPlayers .header-pagination {

    margin-right: 0px !important;
    margin-top: 10px !important;
}

.OrganisationPlayers .header-button {

    margin-right: 5px !important;
}

.OrganisationPlayers h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.OrganisationPlayers h1 > .dropdown {
    float: right !important;
    position: relative;
    right: 10px;
    bottom: 5px;
    top:3px;
}

#editButton {
	margin-left: 10px;
}

#deleteButton {
	margin-right: 5px;
}

#players-btn-cell {
	margin: 0;
	padding: 5px;
}

.OrganisationInvites {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.OrganisationInvites .page-header {
    margin-top: 15px;
}

.OrganisationInvites h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;    
}

#editButton {
    margin-left: 10px;
}

#deleteButton {
    margin-right: 5px;
}

#OrganisationMediaLibrariesTable > table > tbody > tr:nth-child(1) > td:nth-child(4) {
    text-align:center;
    width: 190px;
}

.OrganisationMediaLibrariesTable table {
    margin-bottom: 0px

}

.OrganisationMediaLibrariesTable .rules {
    margin-top: 10px;
    display: block;
    clear:both;
    text-align: right;

}

.OrganisationMediaLibrariesTable tr td:first-child {
    /* vertical-align: middle; */
    padding-top: 18px;
    font-weight: bold;
}
.OrganisationMediaLibraries {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.OrganisationMediaLibraries .page-header {
    margin-top: 15px;
}

.OrganisationMediaLibraries h1 > .btn {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.OrganisationMediaLibraries .header-button {

    margin-right: 5px !important;
}
.OrganisationTextLibraries {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.OrganisationTextLibraries .page-header {
    margin-top: 15px;
}

.OrganisationTextLibraries h1 > .btn {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.OrganisationPlayer {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.OrganisationPlayer .page-header {
    margin-top: 15px;
    min-height:50px;
}

.OrganisationPlayer h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.OrganisationPlayer .header-button {

    margin-right: 5px !important;
}

.OrganisationAttributes {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.OrganisationAttributes .page-header {
    margin-top: 15px;
}

.OrganisationAttributes h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.OrganisationSnippets {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.OrganisationSnippets .page-header {
    margin-top: 15px;
}

.OrganisationSnippets h1 > .btn {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.OrganisationPages {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.OrganisationPages .page-header {
    margin-top: 15px;
}

.OrganisationPages h1 > .btn {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.EditOrganisationNotificationSettingsForm .nopadding {
   padding: 0 !important;
   margin: 0 !important;
}

.EditOrganisationNotificationEmailSettingsForm .nopadding {
    padding: 0 !important;
    margin: 0 !important;
 }

:root {
  color: #565656;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 1.7;
}

.input {
  width: 100%;
  height: 45px;
  padding: 0 1rem;
  margin-top: 1rem;
  box-sizing: border-box;
  font: inherit;
  border-radius: 0.2rem;
  border: 2px solid #d4d5d6;
  color: #565656;
  -webkit-appearance: none;
}

.input:focus {
  border-color: cornflowerblue;
  outline: none;
}

.input.has-error {
  border-color: #dddddd;
}

.error {
  margin: 0;
  font-size: 90%;
  color: red;
}

.tag-item {
  background-color: #d4d5d6;
  display: inline-block;
  font-size: 14px;
  border-radius: 30px;
  height: 30px;
  padding: 0 4px 0 1rem;
  display: inline-flex;
  align-items: center;
  margin: 0 0.3rem 0.3rem 0;
}

.tag-item > .button {
  background-color: white;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font: inherit;
  margin-left: 10px;
  font-weight: bold;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}


.tag-item > .button_active {
  background-color: #5cb85c;
  color:white;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font: inherit;
  margin-left: 10px;
  font-weight: bold;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.OrganisationSettings {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.OrganisationSettings .page-header {
    margin-top: 15px;
}

.OrganisationSettings h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.OrganisationNotificationRules {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.OrganisationNotificationRules .page-header {
    margin-top: 15px;
    min-height:50px;
}

.OrganisationNotificationRules h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.OrganisationNotificationRules #previewButton {
    margin-right: 10px;
}

.EditOrganisationNotificationRule {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.EditOrganisationNotificationRule .page-header {
    margin-top: 15px;
}

.EditOrganisationNotificationRule h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}
.CreateTextLibraryTextRuleForm .nopadding {
    padding: 0 !important;
    margin: 0 !important;
 }
 
.CreateOrganisationNotificationRule {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.CreateOrganisationNotificationRule .page-header {
    margin-top: 15px;
}

.CreateOrganisationNotificationRule h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}
.OrganisationNotificationEmailRules {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.OrganisationNotificationEmailRules .page-header {
    margin-top: 15px;
    min-height:50px;
}

.OrganisationNotificationEmailRules h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.OrganisationNotificationEmailRules #previewButton {
    margin-right: 10px;
}

.EditOrganisationNotificationEmailRule {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.EditOrganisationNotificationEmailRule .page-header {
    margin-top: 15px;
}

.EditOrganisationNotificationEmailRule h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}
.CreateOrganisationNotificationEmailRuleForm .nopadding {
    padding: 0 !important;
    margin: 0 !important;
 }
.CreateOrganisationNotificationEmailRule {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.CreateOrganisationNotificationEmailRule .page-header {
    margin-top: 15px;
}

.CreateOrganisationNotificationEmailRule h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}
.MediaLibraryRules {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.MediaLibraryRules .page-header {
    margin-top: 15px;
    min-height:50px;
}

.MediaLibraryRules h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.MediaLibraryRules #previewButton {
    margin-right: 10px;
}

.MediaLibraryRules h1 > .btn {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.MediaLibraryRules .buttonContainer {
    float: inline-end;
    margin-right: -5px;
    vertical-align: middle;
}
.MediaLibraryRules .logicSeperator {
    margin-bottom: 20px;
}
.EditMediaLibraryRuleForm .nopadding {
    padding: 0 !important;
    margin: 0 !important;
 }
 
.EditMediaLibraryRule {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.EditMediaLibraryRule .page-header {
    margin-top: 15px;
}

.EditMediaLibraryRule h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.CreateMediaLibraryRuleForm .nopadding {
    padding: 0 !important;
    margin: 0 !important;
 }
 
.CreateMediaLibraryRule {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.CreateMediaLibraryRule .page-header {
    margin-top: 15px;
}

.CreateMediaLibraryRule h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}
.CreatePlayerForm .nopadding {
   padding: 0 !important;
   margin: 0 !important;
}

.CreateAttribute {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.CreateAttribute .page-header {
    margin-top: 15px;
}

.CreateAttribute h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

.EditAttributeForm .nopadding {
   padding: 0 !important;
   margin: 0 !important;
}

.EditAttribute {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.EditAttribute .page-header {
    margin-top: 15px;
}

.EditAttribute h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}


.InviteUser {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.InviteUser .page-header {
    margin-top: 15px;
}

.InviteUser h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

@media all {

    .Login {
        padding: 0px 0;
        background-color: #f5f5f5;
        position: absolute;
        top: 0px;
        bottom: 0px;
        width: 100%;
    }

    .Login form {
        margin: 15px auto;
    }

    .Login img {
        margin: 25px auto;
    }

    .Login .panel {
        padding: 10px;
        margin-top: 40px;
    }

    .CenteredLinks small {
        text-align: center;
    }
}

.UserSettings {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.UserSettings .page-header {
    margin-top: 15px;
}

.UserSettings h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

@media all {

    .ResetPassword {
        padding: 0px 0;
        background-color: #f5f5f5;
        position: absolute;
        top: 0px;
        bottom: 0px;
        width: 100%;
    }

    .ResetPassword form {
        margin: 15px auto;
    }

    .ResetPassword img {
        margin: 25px auto;
    }

    .ResetPassword .panel {
        padding: 10px;
        margin-top: 40px;
    }
}

@media all {

    .Forgotten {
        padding: 0px 0;
        background-color: #f5f5f5;
        position: absolute;
        top: 0px;
        bottom: 0px;
        width: 100%;
    }

    .Forgotten form {
        margin: 15px auto;
    }

    .Forgotten img {
        margin: 25px auto;
    }

    .Forgotten .panel {
        padding: 10px;
        margin-top: 40px;
    }
}

@media all {

    .Register {
        padding: 0px 0;
        background-color: #f5f5f5;
        position: absolute;
        top: 0px;
        bottom: 0px;
        width: 100%;
    }

    .Register form {
        margin: 15px auto;
    }

    .Register img {
        margin: 25px auto;
    }

    .Register .panel {
        padding: 10px;
        margin-top: 40px;
    }
}

.PageNotFound {
    padding-top: 100px;
    text-align: center;
}

@media all {

    .AcceptInvite {
        padding: 0px 0;
        background-color: #f5f5f5;
        position: absolute;
        top: 0px;
        bottom: 0px;
        width: 100%;
    }

    .AcceptInvite form {
        margin: 15px auto;
    }

    .AcceptInvite img {
        margin: 25px auto;
    }

    .AcceptInvite .panel {
        padding: 10px;
        margin-top: 40px;
    }
}

.Terms {
    padding-top: 100px;
    text-align: center;
    margin-left: 20%;
    margin-right: 20%;
}

.termsList {
    text-align: left;

}
.PrivacyPolicy {
    padding-top: 50px;
    text-align: center;
    margin-left: 20%;
    margin-right: 20%;
}

.AccessDenied {
    padding-top: 100px;
    text-align: center;
}

.App {
    margin-top: 15px;
}

