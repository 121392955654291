.Organisation {
    margin-top: 35px;
    padding: 0px 10px 0px 10px;
}

.Organisation .page-header {
    margin-top: 15px;
}

.Organisation h1 > a {
    float: right;
    position: relative;
    right: 0px;
    bottom: 5px;
    top:3px;
}

/* .footer-tooltip-button {
    padding: 2px 2px 2px 2px !important;
    padding-bottom: 2px !important;
    margin-top: 2px !important;
    margin-bottom: 0 !important;
    line-height: 1 !important;
} */
